import _ from 'lodash'
import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useTranslation } from 'react-i18next'
import {
  Spinner,
  FilterButtons,
  BlockDownloads,
  SelectComponent,
  Seo,
} from '../../components'
import './download-styles.scss'

const DownloadView = ({
  loading, onHandleFilter, tabs, data,
}) => {
  const breakpoints = useBreakpoint()
  const { t } = useTranslation()
  const tabsObj = _.map(tabs, (tab, idx) => {
    if (idx === 0) {
      return {
        id: idx,
        name: tab.name,
        slug: tab.slug,
        label: tab.name,
        value: tab.slug,
        activeClass: 'filter-active',
        translateValue: '0',
      }
    }
    return {
      id: idx,
      name: tab.name,
      slug: tab.slug,
      label: tab.name,
      value: tab.slug,
      activeClass: '',
      translateValue: `${180 * idx}px`,
    }
  })

  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <div className="download__container container-fluid">
      <Seo title={`${t('seo.title')}: Download Center`} />
      <div className="download__box">
        <div className="download__head">
          <h1 className="page-title col-12">{t('download.title')}</h1>
        </div>
        <div className="download__filters">
          {breakpoints.xs ? (
            <SelectComponent
              blue={true}
              placeholder={t('keyTerms.filter.placeholder', {
                number: tabsObj.length,
              })}
              options={tabsObj}
              onChange={(e) => onHandleFilter(e.value)}
            />
          ) : (
            <FilterButtons
              width={180}
              data={tabsObj}
              onHandleFilter={onHandleFilter}
            />
          )}
        </div>
        <div className="download__section">
          {_.map(data.downloads, (item, idx) => (
            <BlockDownloads key={idx} data={item} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DownloadView
