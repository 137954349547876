import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import DonwloadView from './download-view'

const DownloadController = () => {
  const [loading, setLoading] = useState(true)
  const [downloads, setDownloads] = useState([])
  const { i18n } = useTranslation()
  const [downloadsSelected, setDownloadsSelected] = useState([])
  const [tabs, setTabs] = useState([])

  const getDownloads = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'claims-support/downloads',
      params: {
        lang: i18n.language,
      },
    })
      .then(({ data }) => {
        setDownloads(data.content)
        setTabs(data.tab)
        setDownloadsSelected(_.head(data.content))
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }
  const onHandleFilter = (slug) => {
    const filtered = _.filter(downloads, { slug })
    setDownloadsSelected(_.head(filtered))
  }

  useEffect(() => {
    getDownloads()
  }, [i18n.language])

  const viewProps = {
    data: downloadsSelected,
    loading,
    tabs,
    onHandleFilter,
  }
  return <DonwloadView {...viewProps} />
}

export default DownloadController
